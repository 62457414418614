
html,body{
  height: 100%;
  margin: 0;
}


.app {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100svh;
  align-items: center;
  justify-content: space-between;
}

.footnote{
  font-size: 0.8em;
  line-height: 0.01em;
  text-align: center;
  color: gray;
}
